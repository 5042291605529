<template>
  <main>
    <div id="printTab">
      <div class="one">
        <div class="main-header">
          <span class="main-title">营业报告</span>
          <span class="add" @click="routerToFun('moreTaking')">更多&nbsp;＞</span>
        </div>
        <div class="main-middle" style="text-align: center;">
          <el-table 
            :data="tableDataOne" 
            border 
            style="width: 100%"
            :cell-style="textStyle" 
            :header-cell-style="tabHeaderStyle"
            @cell-click="clickFun"
          >
            <el-table-column prop="shopName" label="店铺名称" />
            <el-table-column prop="collect" label="结算汇总"  />
            <el-table-column  prop="totalIncome">
              <template #header>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="营业收入=结算汇总-优惠折扣。营业收入=净收入-会员卡充值+会员卡消费"
                  placement="top-start"
                >
                  <el-button style="border:none;font-size:16px;background-color:#f5f7fa">营业收入❓</el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="totalDiscount" label="优惠折扣" />
          </el-table>
        </div>
      </div>
      <div class="two">
        <div class="main-header">
          <span class="main-title">净收入统计</span>
          <span class="add" @click="routerToFun('moreIncome')">更多&nbsp;＞</span>
        </div>
        <div class="main-middle">
          <el-table 
            :data="tableDataTwo" 
            style="width: 100%"
            :header-cell-style="twoTabHeaderStyle"
            :cell-style="textStyle"
            @cell-click="clickFun"
          >
            <el-table-column prop="shopName" label="店铺名称"/>
              <el-table-column prop="netIncome" label="净收入"/>
              <el-table-column label="收入">
                <el-table-column prop="cash" label="现金收入" />
                <el-table-column prop="bankMoney" label="网银收入" />
                <el-table-column prop="alipayMoney" label="支付宝收入" />
                <el-table-column prop="wechartMoney" label="微信收入" />
                <el-table-column prop="kdbScanPay" label="扫码收入" />
                <el-table-column prop="groupMoney" label="团购收入" />
                <el-table-column prop="earning" label="收入合计" />
              </el-table-column>
              <el-table-column label="支出">
                <el-table-column prop="outlayTotal" label="现金支出" />
                <el-table-column prop="bankExpend" label="网银支出" />
                <el-table-column prop="alipayExpend" label="支付宝支出"/>
                <el-table-column prop="wechartExpend" label="微信支出" />
                <el-table-column prop="kdbHandling" label="手续费" />
                <el-table-column prop="expend" label="支出合计" />
              </el-table-column>
            <!-- <el-table-column prop="shopName" label="店铺名称" width="120" />
              <el-table-column prop="netIncome" label="净收入" width="120" />
              <el-table-column label="收入">
                <el-table-column prop="cash" label="现金收入" />
                <el-table-column prop="bankMoney" label="网银收入" />
                <el-table-column prop="alipayMoney" label="支付宝收入" width="120" />
                <el-table-column prop="wechartMoney" label="微信收入" />
                <el-table-column prop="kdbScanPay" label="扫码收入" />
                <el-table-column prop="groupMoney" label="团购收入" />
                <el-table-column prop="earning" label="收入合计" />
              </el-table-column>
              <el-table-column label="支出">
                <el-table-column prop="outlayTotal" label="现金支出" />
                <el-table-column prop="bankExpend" label="网银支出" />
                <el-table-column prop="alipayExpend" label="支付宝支出" width="120" />
                <el-table-column prop="wechartExpend" label="微信支出" />
                <el-table-column prop="kdbHandling" label="手续费" />
                <el-table-column prop="expend" label="支出合计" />
              </el-table-column> -->
          </el-table>
        </div>
        <!-- <div class="main-footer">
        </div> -->
      </div>
      <div class="three">
        <div class="main-header">
          <span class="main-title">会员</span>
          <span class="add"  @click="routerToFun('moreVipDetails')">更多&nbsp;＞</span>
        </div>
        <div class="main-middle">
          <el-table 
            :data="tableDataThree" border 
            style="width: 100%"
            :cell-style="textStyle" 
            :header-cell-style="twoTabHeaderStyle"
            @cell-click="clickFun"
          >
            <el-table-column prop="shopName" label="店铺名称" />
            <el-table-column prop="newMember" label="新增会员"  />
            <el-table-column prop="membership" label="会员总数"  />
            <!-- <el-table-column prop="startMoney" label="期初余额" /> -->
            <el-table-column label="会员充值">
              <el-table-column prop="topUp" label="会员卡充值"  width="120" />
              <el-table-column prop="realMoney" label="充值金额" />
              <el-table-column prop="handselMoney" label="赠送金额" />
              <el-table-column prop="rechargeNumber" label="会员卡充值人数" width="150" />
            </el-table-column>
            <el-table-column label="会员消费">
              <el-table-column prop="expendMoney" label="会员消费" />
              <el-table-column prop="consumeNumber" label="会员卡消费人数" width="150" />
            </el-table-column>
            <!-- <el-table-column prop="overMoney" label="期末余额" /> -->
          </el-table>
        </div>
      </div>
    </div>
    <Print rul='report/chainStatisticsExport.json' />
  </main>
</template>
<script>
import { ref, defineComponent ,watch  } from 'vue'
import { useRouter ,useRoute } from 'vue-router'
// import {print} from'@u/print'
import {postData} from'@a'
// import Print from '@c/util/print.vue'
import {ElNotification} from'element-plus'
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    let tableDataOne = ref([])
    let tableDataTwo = ref([])
    let tableDataThree = ref([])
    // 表格样式
    const textStyle = ({ row, column })=>{
      let style={}
      let text = 'text-'
      style[text + 'align']='center'
      style.color = '#333333'
      let font = 'font-'
      style[font+'size'] = '14px'
      style[font+'weight'] = 700
      if(column.label === '店铺名称' && row.shopName !== "合计"){
        style[font+'family'] = 'Arial Normal', 'Arial'
        style[font+'style'] = 'normal'
        style[font+'size'] = '15px'
        style[text+'decoration'] = 'underline'
        style.color = '#50A0D5'
        style.cursor= 'pointer'
      }
      if(column.label === '现金收入' 
      || column.label === '网银收入' 
      || column.label === '支付宝收入' 
      || column.label === '微信收入' 
      || column.label === '扫码收入' 
      || column.label === '团购收入' 
      || column.label === '收入合计' 
      ){
        style.color = '#FF0000'
      }
      if(column.label === '现金支出'
      ||column.label === '网银支出' 
      ||column.label === '支付宝支出' 
      ||column.label === '微信支出' 
      ||column.label === '手续费' 
      ||column.label === '支出合计' 
      ){
        style.color = '#009900'
      }
      if(
        column.label === '充值金额' ||
        column.label === '赠送金额'
      ) style['font-weight'] = 400
      if(row.shopName === "合计"){
        style['background-color'] = "#D9EFFF"
        // style['background-color'] = "#61AEE6"
      }
      return style
    }
    const tabHeaderStyle = ()=>{
      let style={}
      let text = 'text-align'
      style[text]='center'
      let font = 'font-'
      style[font+'size'] = '15px'
      style[font+'weight'] = '400'
      style.color = '#333333'
      style['background-color'] = '#f5f7fa'
      // style.border = '1px solid #757575'
      return style
    }
    const twoTabHeaderStyle = (row)=>{
      let style={}
      let text = 'text-align'
      style[text]='center'
      let font = 'font-'
      style[font+'size'] = '15px'
      style.color = '#333333'
      style['font-weight'] = 700
      if(row.column.label === "收入合计" || row.column.label === "收入"){
        // style["border-right"] = '1px solid rgb(133 133 133)'
      }
      // if(
      //   row.column.label === '会员卡充值' ||
      //   row.column.label === '充值金额' ||
      //   row.column.label === '赠送金额' ||
      //   row.column.label === '会员卡充值人数' ||
      //   row.column.label === '会员卡消费' ||
      //   row.column.label === '会员卡消费人数' 
      // ) style['font-weight'] = 400
      if(
        row.column.label === '会员卡充值' ||
        row.column.label === '充值金额' ||
        row.column.label === '赠送金额' ||
        row.column.label === '会员卡充值人数' ||
        row.column.label === '现金支出' ||
        row.column.label === '网银支出' ||
        row.column.label === '支付宝支出' ||
        row.column.label === '微信支出' ||
        row.column.label === '手续费' ||
        row.column.label === '支出合计'||
        row.column.label === "会员充值" || 
        row.column.label === "支出"
      ) style['background-color'] = '#E4E4E4'
      return style
    }
    const clickFun = (row, column)=>{
      if(column.label==="店铺名称" && row.shopName != "合计"){
        let newDateMin =  (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        router.push({name:'aloneShop',query:{shopName:row.shopName,shopId:row.shopId,dateType:route.query.dateType?route.query.dateType:'日',dateMin:route.query.dateMin?route.query.dateMin:newDateMin,dateMax:route.query.dateMax?route.query.dateMax:''}})
      }
    }
    const routerToFun = name=>{
      router.push({name,query:{}})
    }
    let date = new Date()
    let param = {}
    let type = route.query.dateType?route.query.dateType:'日'
    const infoData = async () => {
      type = route.query.dateType?route.query.dateType:'日'
      if(!route.query.dateMin && type !== '日'){
        ElNotification({
          message: '缺少参数请求失败',
          type: '加载失败',
          duration: 5 * 1000,
          showClose: true
        })
        return
      }
      switch(type){
        case '日':
          if(route.query.dateMin){
            param.from = route.query.dateMin
            if(route.query.dateMax)
            param.to = route.query.dateMax
          }else{
            let date = new Date()
            param.from = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0'+(date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):'0'+(date.getDate()))
          }
          param.dutyType = 0
          reqDayData(param)
          break;
        case '周':
          if(route.query.dateMin){
            param.from = route.query.dateMin
            if(route.query.dateMax)
            param.to = route.query.dateMax
          }else{
            param.from = (date.getFullYear()) + '-' + (date.getMonth() + 1) + '-' + (date.getDate())
          }
          param.dutyType = 0
          reqDayData(param)
          break;
        case '月':
          if(route.query.dateMin){
            param.from = route.query.dateMin
            if(route.query.dateMax)
            param.to = route.query.dateMax
          }else{
            // param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
            param.from = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
          }
          param.dutyType = 0
          reqDayData(param)
          break;
        case '季':
          if(route.query.dateMin){
            param.from = route.query.dateMin
            param.to = route.query.dateMax
          }else{
            param.from = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
          }
          param.dutyType = 0
          reqDayData(param)
          break;
        case '年':
          if(route.query.dateMin){
            param.from = route.query.dateMin + '-01-01'
            param.to = route.query.dateMin + '-12-31'
          }else{
            param.from = date.getFullYear()
          }
          param.dutyType = 0
          reqDayData(param)
          break;
        case '自定义':
          param.from = route.query.dateMin
          param.to = route.query.dateMax
          param.dutyType = 0
          reqDayData(param)
          break;
        default:
          router.push({name:'home'})
          break;
      }
    }
    const reqDayData = async (param)=>{
      try {
          let res = await postData('shop/chainStatistics.json',param)
         formattingData(res)
      } catch (err) {
          console.log(err)
      }
      try {
          let res = await postData('shop/chainMemberStatistics.json',param)
          if(!res.data) return
          vipTableData(res)
      } catch (err) {
          console.log(err)
      }
    }
    const vipTableData = (res)=>{
      // 格式化会员的数据
      tableDataThree.value = []
      for(let i = 0;i<res.data.length;i++){
        let obj = {}
        obj.shopName = res.data[i].shopName
        obj.shopId = res.data[i].shopId
        obj.newMember = res.data[i].newMember  //新增
        obj.membership = res.data[i].membership  //会员人数
        obj.startMoney = 0 //期初余额
        obj.overMoney = 0 //期末余额
        obj.topUp = (res.data[i].realMoney + res.data[i].handselMoney-0).toFixed(2) //会员卡充值
        obj.realMoney = (res.data[i].realMoney-0).toFixed(2)//充值金额
        obj.handselMoney = (res.data[i].handselMoney-0).toFixed(2) //充值赠送
        obj.rechargeNumber = res.data[i].rechargeNumber //充值人数
        obj.expendMoney = (res.data[i].expendMoney-0).toFixed(2) //会员卡消费
        obj.consumeNumber = res.data[i].consumeNumber //会员卡消费人数
        tableDataThree.value.push(obj)
      }
      //算出结算
      // tableDataThree.value.push({})
      let obj = {
        shopName :'合计',
        newMember : 0,
        membership : 0,
        startMoney : 0,
        overMoney : 0,
        topUp : 0,
        realMoney : 0,
        handselMoney : 0,
        rechargeNumber : 0,
        expendMoney : 0,
        consumeNumber : 0
      }
      for(let i = 0;i<tableDataThree.value.length;i++){
        obj.newMember += tableDataThree.value[i].newMember-0
        obj.membership += tableDataThree.value[i].membership-0
        obj.startMoney += tableDataThree.value[i].startMoney-0
        obj.overMoney += tableDataThree.value[i].overMoney-0
        obj.topUp += tableDataThree.value[i].topUp-0
        obj.realMoney += tableDataThree.value[i].realMoney-0
        obj.handselMoney += tableDataThree.value[i].handselMoney-0
        obj.rechargeNumber += tableDataThree.value[i].rechargeNumber-0
        obj.expendMoney += tableDataThree.value[i].expendMoney-0
        obj.consumeNumber += tableDataThree.value[i].consumeNumber-0
      }
      for(let key in obj){
        if(key === 'shopName' || key === 'newMember' || key === 'membership' || key === 'consumeNumber' || key === 'rechargeNumber') continue
        obj[key] = (obj[key]-0).toFixed(2)
      }
      tableDataThree.value.push(obj)
    }
    // 格式化营业收入、净收入
    const formattingData = data=>{
      if(!data.data) return
      tableDataOne.value = []
      tableDataTwo.value = []
      for(let i = 0;i<data.data.length;i++){
        //tableDataOne
        let obj = {}
        obj.totalIncome = (data.data[i].totalIncome-0).toFixed(2)
        obj.shopName = data.data[i].shopName
        obj.shopId = data.data[i].shopId
        obj.totalDiscount = (data.data[i].totalDiscount-0).toFixed(2)
        obj.collect = (data.data[i].totalDiscount + data.data[i].totalIncome-0).toFixed(2)  //结算汇总
        tableDataOne.value.push(obj)
        //tableDataTwo
        let income = {}
        income.shopName = data.data[i].shopName
        income.shopId = data.data[i].shopId
        income.outlayTotal = ((data.data[i].cashExpend - 0) + (data.data[i].outlayTotal - 0)).toFixed(2)//现金支出
        income.alipayExpend = (data.data[i].alipayExpend).toFixed(2) //支付宝支出
        income.wechartExpend = (data.data[i].wechartExpend).toFixed(2) //微信支出
        income.bankExpend =(data.data[i].bankExpend).toFixed(2) //网银支出
        income.kdbHandling =data.data[i].kdbHandling.toFixed(2) //手续费
        income.expend = ((income.alipayExpend - 0)  + (income.wechartExpend - 0)  + (income.bankExpend - 0) + (income.kdbHandling - 0) + (income.outlayTotal - 0)).toFixed(2)//总计支出
        income.cash = data.data[i].cashIncomeSummary //现金收入
        income.alipayMoney = data.data[i].alipayIncomeSummary //支付宝收入
        income.bankMoney = data.data[i].backIncomeSummary//网银收入
        income.wechartMoney = data.data[i].wechartIncomeSummary//微信收入
        income.groupMoney = data.data[i].groupIncomeSummary //团购收入
        income.kdbScanPay = data.data[i].kdbScanIncomeSummary//扫码收入
        //总计收入
        income.earning =  ((income.wechartMoney - 0) + (income.bankMoney - 0) + (income.groupMoney - 0) + (income.kdbScanPay - 0) + (income.cash - 0)  + (income.alipayMoney-0)).toFixed(2)
        //net income 净收入
        income.netIncome = (data.data[i].netIncome).toFixed(2)
        tableDataTwo.value.push(income)
      }
      // 营业报告合计
      let obj = {
        totalIncome:0,
        shopName:'合计',
        totalDiscount:0,
        collect:0,
      }
      for(let i = 0;i<tableDataOne.value.length;i++){
        obj.totalIncome += tableDataOne.value[i].totalIncome-0
        obj.totalDiscount += tableDataOne.value[i].totalDiscount-0
        obj.collect += tableDataOne.value[i].collect-0  //结算汇总
      }
      for(let key in obj){
        if(key === 'shopName') continue
        obj[key] = (obj[key]-0).toFixed(2)
      }
      tableDataOne.value.push(obj)
      // 净收入合计
      let income = {
        shopName:'合计',
        outlayTotal :0,
        alipayExpend :0,
        wechartExpend : 0,
        bankExpend :0,
        expend :0,
        cash :0,
        alipayMoney : 0,
        bankMoney :0,
        wechartMoney :0,
        groupMoney : 0,
        kdbScanPay :0,
        kdbHandling:0,
        //总计收入
        earning :0,
        //net income 净收入
        netIncome :0,
      }
      for(let i = 0;i< tableDataTwo.value.length;i++){
        income.outlayTotal += tableDataTwo.value[i].outlayTotal-0
        income.alipayExpend += tableDataTwo.value[i].alipayExpend-0
        income.wechartExpend += tableDataTwo.value[i].wechartExpend-0
        income.bankExpend += tableDataTwo.value[i].bankExpend-0
        income.expend += tableDataTwo.value[i].expend-0
        income.cash += tableDataTwo.value[i].cash-0
        income.alipayMoney += tableDataTwo.value[i].alipayMoney-0
        income.bankMoney += tableDataTwo.value[i].bankMoney-0
        income.wechartMoney += tableDataTwo.value[i].wechartMoney-0
        income.groupMoney += tableDataTwo.value[i].groupMoney-0
        income.kdbScanPay += tableDataTwo.value[i].kdbScanPay-0
        //总计收入
        income.kdbHandling += tableDataTwo.value[i].kdbHandling-0
        income.earning +=  tableDataTwo.value[i].earning-0
        //net income 净收入
        income.netIncome += tableDataTwo.value[i].netIncome-0
      }
      for(let key in income){
        if(key === 'shopName') continue
        income[key] = (income[key]-0).toFixed(2)
      }
      tableDataTwo.value.push(income)
    }
    // 监听url传递参数的变化
    watch(()=>route.query,()=>{
      if(route.path == '/home/report/togetherShop'){
        // type = route.query.dateType
        infoData()
      }
    })
    infoData() //初始化数据
    return {
      tableDataOne,
      tableDataTwo,
      tableDataThree,
      textStyle,       //单元格样式和单独单元格样式
      tabHeaderStyle,  //头部样式
      twoTabHeaderStyle,   //第二个头部
      clickFun,        //行内被点击
      routerToFun,
    }
  },
})
</script>
<style scoped>
.shop-name{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  text-align: left;
}
#printTab>div{
  margin-bottom: 20px;
}
.main-header{
  height: 55px;
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
    /* background-color: #e9e9e9; */
    /* background-color: #61b6ca; */
}
.main-title{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-align: left;
}
.add{
  color: #169BD5;
  font-size: 15px;
  cursor: pointer;
}
</style>
